import { isServer } from './isServer';

const DEFAULT_ID = '1';

export function getTestScope(variants = 2, df = DEFAULT_ID) {
  if (!isServer) {
    try {
      const randomId = parseInt(
        localStorage.getItem('randomId') ?? df
      ) as number;
      return randomId % variants;
    } catch (e) {
      // console.log(e)
    }
  }
  return 0;
}

export function homeAbTest(): number {
  const testScope = getTestScope(2, DEFAULT_ID);
  if (!isServer) {
    window['dataLayer']?.push({
      event: 'home_abc_test',
      experimentNameC3: 'mobile_datepicker',
      experimentIDC3: testScope == 1 ? 'variant1' : 'control',
    });
  }
  return testScope;
}

const CONFIRMED_ID = '0';

export function confirmedAbTest(): number {
  const testScope = getTestScope(3, CONFIRMED_ID);
  if (!isServer) {
    window['dataLayer']?.push({
      event: 'confirmed_ab_test',
      experimentName: 'confirmed_kayak',
      experimentID:
        testScope == 2 ? 'variant2' : testScope == 1 ? 'variant1' : 'control',
    });
  }
  return testScope;
}

const PARKINGAT_DEFAULT_ID = '0';

export function parkingAtAbTest(): number {
  const testScope = getTestScope(2, PARKINGAT_DEFAULT_ID);
  if (!isServer) {
    window['dataLayer']?.push({
      event: 'parkingat_ab_test',
      experimentNameC2: 'countdown',
      experimentIDC2: testScope ? 'variant1' : 'control',
    });
  }
  return testScope;
}
